// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.app = {};
require("@rails/ujs").start();
require("turbolinks").start();
require("bootstrap");
require("../src/utilities/prototype");

import guestChannel from "channels/guests_channel";
import {
  initPublicPoll,
  registerVote,
  toggleTruncate,
} from "../src/forms/poll";
import { validateCommentLength } from "../src/forms/validation";

document.addEventListener("turbolinks:load", () => {
  const currentTopic = document.getElementById("currentTopic");
  if (currentTopic) guestChannel(currentTopic.dataset.eventId);
  initPublicPoll();
});

window.app.validateCommentLength = validateCommentLength;
window.app.registerVote = registerVote;
window.app.toggleTruncate = toggleTruncate;
