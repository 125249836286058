import consumer from "./consumer";
import { initPublicPoll } from "../src/forms/poll";

export default function guestChannel(event_id) {
  consumer.subscriptions.create(
    {
      channel: "GuestsChannel",
      event_id,
    },
    {
      connected() {},

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        if (data.command === "newCurrentTopic") {
          const object = $("#currentTopic").children(":first");
          if ($("#commentForm").length) {
            $(data.topic.html).appendTo(object).hide();
          } else {
            object.html(data.topic.html);
          }
          initPublicPoll();
        }
        // Called when there's incoming data on the websocket for this channel
      },
    }
  );
}
