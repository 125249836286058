export function initPublicPoll() {
  const voteCards = document.querySelectorAll(".voteCard");
  if (voteCards.length) {
    voteCards.forEach((n) => {
      n.addEventListener("click", (event) => {
        if (!event.target.classList.contains("read-more"))
          updateVoteSubmit(n.dataset.commentId);
      });
    });
    checkVoted();
  }
}

function updateVoteSubmit(selected) {
  const submit = document.getElementById("submitVote");
  const prev = submit.dataset.commentId;
  const prevEl = document.querySelector(`.voteCard[data-comment-id="${prev}"]`);

  if (prevEl) prevEl.classList.remove("shadow-primary");
  document
    .querySelector(`.voteCard[data-comment-id="${selected}"]`)
    .classList.add("shadow-primary");

  submit.href = submit.href.replace(
    /comments\/\d+\/vote/,
    `comments/${selected}/vote`
  );
  submit.dataset.commentId = selected;

  if (submit.getAttribute("disabled")) {
    submit.removeAttribute("disabled");
    submit.classList.remove("disabled");
  }
}

export function registerVote(topic_id, cast) {
  const prev_voted = localStorage.getObject("topics_voted") || [];
  prev_voted.push({ id: topic_id, cast: cast });
  localStorage.setObject("topics_voted", prev_voted);
  checkVoted();
}

function checkVoted() {
  const topic = document.querySelector("#voteCards").dataset;
  const topicID = Number(topic.topicId);
  const lastReset = Number(topic.pollLastReset);
  const prev_voted = localStorage.getObject("topics_voted") || [];
  const alreadyVoted = prev_voted.some(
    (t) => t.id == topicID && t.cast > lastReset
  );

  if (!alreadyVoted) return false;

  document.querySelector("#voteCards").remove();
  document.querySelector("#alreadyVoted").classList.remove("d-none");
}

export function toggleTruncate(event) {
  event.preventDefault();
  const target = event.target.parentElement;
  const sibling = target.nextElementSibling ?? target.previousElementSibling;
  target.classList.add("d-none");
  sibling.classList.remove("d-none");
}
