// Validate number of characters in textarea
export function validateCommentLength() {
  $(".textarea-comment").on("input", function () {
    const maxLength = $(this).attr("maxlength");
    const currentLength = $(this).val().length;
    if (currentLength < maxLength) {
      $("#comment-content-counter").html(`${currentLength}/${maxLength}`);
    } else {
      $("#comment-content-counter").html(`${currentLength}/${maxLength}`);
      $("#comment-content-counter").addClass("limit-reached");
    }
  });
  $("textarea[data-limit-rows=true]").on("keypress", function (e) {
    const textarea = $(this),
      text = textarea.val(),
      numberOfLines = (text.match(/\n/g) || []).length + 1,
      maxRows = Number(textarea.attr("maxrows"));
    if (e.which === 13 && numberOfLines === maxRows) {
      return false;
    }
  });
}

export function showActiveProjectHelp() {
  if ($("#setProjectActiveHelp").length) {
    $("#event_status_active")
      .parent()
      .on("click", function () {
        $("#setProjectActiveHelp").removeClass("d-none");
      });
    $("#event_status_inactive")
      .parent()
      .on("click", function () {
        $("#setProjectActiveHelp").addClass("d-none");
      });
  }
}
