Storage.prototype.setObject = function (key, value) {
  this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};

Array.prototype.replaceOrPush = function (object) {
  const index = this.findIndex((v) => v.id === object.id);
  index === -1 ? this.push(object) : (this[index] = object);
};
